import { format, getTime, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

export function fDateOnly(date, format_a = "MM-dd-yyyy") {
  return format(new Date(date), format_a);
}

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}


export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false };
  return date.toLocaleString('fr-FR', options).replace(',', '');
}

export function fTime(date) {
  return format(new Date(date), "HH:mm:ss");
}

export function fDateTimeWithTime(time) {
  
  if (time != null) {
    const splitParts = time?.split(":");
    return new Date(2024, 1, 1, splitParts[0], splitParts[1]);
  }
  return null;
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
