// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_PAGE = {
  PolitiqueConf: "politique-confidentialite",
  TermesConditions: "termes-conditions",
  ReglesJeu: "regles-jeu",
  PolitiqueRemboursement: "politique-remboursement",
  PolitiqueModeration: "politique-moderation",
  ConditionsAffiliation: "conditions-affiliation",
  PolitiqueSecurite: "politique-securite",
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/home"),
  },
  configuration_management: {
    root: path(ROOTS_DASHBOARD, "/configuration"),
  },
  configuration: {
    root: path(ROOTS_DASHBOARD, "/configuration-transaction"),
    exchange_rate: path(
      ROOTS_DASHBOARD,
      "/configuration-transaction/exchange-rate"
    ),
    new_exchange_rate: path(
      ROOTS_DASHBOARD,
      "/configuration-transaction/exchange-rate/new"
    ),
    edit_rate: (id) =>
      path(
        ROOTS_DASHBOARD,
        `/configuration-transaction/exchange-rate/${id}/edit`
      ),
    fee: path(ROOTS_DASHBOARD, "/configuration-transaction/fee"),
    new_fee: path(ROOTS_DASHBOARD, "/configuration-transaction/fee/new"),
    edit_fee: (id) =>
      path(ROOTS_DASHBOARD, `/configuration-transaction/fee/${id}/edit`),
  },
  
  vendor: {
    root: path(ROOTS_DASHBOARD, "/vendor"),
    list: path(ROOTS_DASHBOARD, "/vendor/list"),
    add: path(ROOTS_DASHBOARD, "/vendor/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/vendor/${id}/edit`),
  },
  driver: {
    root: path(ROOTS_DASHBOARD, "/driver"),
    list: path(ROOTS_DASHBOARD, "/driver/list"),
    add: path(ROOTS_DASHBOARD, "/driver/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/driver/${id}/edit`),
  },
  camion: {
    root: path(ROOTS_DASHBOARD, "/camion"),
    list: path(ROOTS_DASHBOARD, "/camion/list"),
    add: path(ROOTS_DASHBOARD, "/camion/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/camion/${id}/edit`),
  },
  trajet: {
    root: path(ROOTS_DASHBOARD, "/trajet"),
    list: path(ROOTS_DASHBOARD, "/trajet/list"),
    add: path(ROOTS_DASHBOARD, "/trajet/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/trajet/${id}/edit`),
  },
  checkpoint: {
    root: path(ROOTS_DASHBOARD, "/checkpoint"),
    list: path(ROOTS_DASHBOARD, "/checkpoint/list"),
    add: path(ROOTS_DASHBOARD, "/checkpoint/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/checkpoint/${id}/edit`),
  },

  facture: {
    root: path(ROOTS_DASHBOARD, "/facture"),
    // list: path(ROOTS_DASHBOARD, "/facture/list"),
    add: path(ROOTS_DASHBOARD, "/facture/new"),
    rapport:path(ROOTS_DASHBOARD, "/facture/rapport"),
    edit: (id) => path(ROOTS_DASHBOARD, `/facture/${id}/edit`),
  },

  succursale: {
    root: path(ROOTS_DASHBOARD, "/succursale"),
    list: path(ROOTS_DASHBOARD, "/succursale/list"),
    add: path(ROOTS_DASHBOARD, "/succursale/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/succursale/${id}/edit`),
  },
  supervisor: {
    root: path(ROOTS_DASHBOARD, "/master-vendor"),
    list: path(ROOTS_DASHBOARD, "/master-vendor/list"),
    add: path(ROOTS_DASHBOARD, "/master-vendor/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/master-vendor/${id}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  tracabilite: {
    supervisor: path(ROOTS_DASHBOARD, "/tracabilite/supervisor"),
    list: path(ROOTS_DASHBOARD, "/tracabilite/list"),
    agent: path(ROOTS_DASHBOARD, "/tracabilite/agent"),
  },
  // ticket: {
  //   root: path(ROOTS_DASHBOARD, "/ticket"),
  //   sale: path(ROOTS_DASHBOARD, "/ticket/sale"),
  //   win: path(ROOTS_DASHBOARD, "/ticket/win"),
  //   view: (id) => path(ROOTS_DASHBOARD, `/ticket/sale/${id}`),
  //   view_win: (id) => path(ROOTS_DASHBOARD, `/ticket/win/${id}`),
  // },
  report: {
    root: path(ROOTS_DASHBOARD, "/report"),
    sale: path(ROOTS_DASHBOARD, "/report/sale"),
    user: path(ROOTS_DASHBOARD, "/report/user"),
  },
  transaction: {
    root: path(ROOTS_DASHBOARD, "/transaction"),
    list: path(ROOTS_DASHBOARD, "/transaction/list"),
    add: path(ROOTS_DASHBOARD, "/transaction/new"),
  },
};

export const PATH_CONTACT = "/#/contact-us";
